import React from "react";
import "./workshop.scss";
import { Container, Row, Col } from "react-bootstrap";

export const WorkshopIntro = ({ children }) => {
  return (
    <div id="description">
      <Container fluid="lg">
        <div className="my-xl-5 my-4 px-2 px-lg-0">{children}</div>
      </Container>
    </div>
  );
};

export const WorkshopIntroImage = ({ children }) => {
  return (
    <Container fluid="lg">
      <div>
        <Row className="justify-content-center align-items-center">
          <Col xs={12}>{children}</Col>
        </Row>
      </div>
    </Container>
  );
};

export const WorkshopIntroQuote = ({ children }) => {
  return (
    <div id="introQuote">
      <Container fluid="lg">
        <Row className="justify-content-center">
          <Col lg={12}>
            <Row className="white-bg pt-xl-5 pt-4">
              <Col className="p-4">
                <p className="highlight-text">
                  <span>{children}</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
