import React from "react";
import "./workshop.scss";
import { Container, Row, Col } from "react-bootstrap";

export const WorkshopInformation = ({ children }) => {
  return (
    <div id="wwww" className="py-md-5 py-4">
      <Container fluid="lg">
        <div className="py-md-5 py-4 px-2 px-lg-0">{children}</div>
      </Container>
    </div>
  );
};

export const WorkshopInfoHalfPanel = ({ classes, icon, title, children }) => {
  return (
    <Col lg={6} xs={12}>
      <Row>
        <Col xs={12} lg className={classes}>
          <Row>
            <Col xs={12} className="d-flex align-items-center mb-3">
              <div className="meridian-blue-15-bg rounded icon d-inline-flex p-2">
                <img src={icon} className="img-fluid" alt={`${title} icon`} />
              </div>
              <span className="d-inline-flex ms-4">
                <h3 className="workshop-info-title">{title}</h3>
              </span>
            </Col>
            <Col xs={12} className="pt-1">
              <p className="workshop-info-description">{children}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export const WorkshopInfoImage = ({ children }) => {
  return (
    <Row className="justify-content-center align-items-center py-xl-5 py-4 my-3">
      <Col xs={12}>{children}</Col>
    </Row>
  );
};

export const WorkshopInfoThirdPanel = ({ classes, icon, children }) => {
  return (
    <Col className={classes}>
      <Row>
        <Col xs={11} className="rounded white-bg p-4 mb-45">
          <Row className="px-3">
            <Col
              xs={12}
              className=" px-0 d-flex align-items-center pb-2 border-bottom"
            >
              <div className="icon d-inline-flex p-2">
                <img src={icon} className="img-fluid" alt={`icon`} />
              </div>
            </Col>
          </Row>
          <Row className="px-3">
            <Col xs={12} className="pt-2 px-0">
              <p className="workshop-info-description">{children}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export const WorkshopCallout = ({ children }) => {
  return (
    <div id="callout">
      <Container fluid="xl">
        <Row className="justify-content-center">
          <Col lg={12}>
            <Row className="white-bg pb-xl-5 pb-4 mb-xl-5 mb-4">
              <Col xs={12} className="mb-4 pb-2">
                <p className="highlight-text">
                  <span>{children}</span>
                </p>
              </Col>
              <Col className="text-center">
                <p>
                  <a href="#tll_events" className="btn btn-primary">
                    See Upcoming Labs{" "}
                    <svg
                      width="7"
                      height="11"
                      viewBox="0 0 7 11"
                      fill="#FFFFFF"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                        fill="#FFFFFF"
                      ></path>
                    </svg>
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
